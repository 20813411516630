@import '@iconscout/unicons/css/line.css';
@import '@iconscout/unicons/css/solid.css';
@import 'react-toggle/style.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html,
	body {
		@apply text-primaryMain antialiased;
		font-family: var(--font-lexend);
	}

	/* TODO: fix scalable way of setting stuff*/
	strong {
		color: inherit;
		font-weight: semi-bold;
	}

	.text-shades0 strong {
		color: var(--colors-grayscale0);
	}

	p strong {
		color: inherit;
	}

	[type='checkbox']:checked,
	[type='radio']:checked {
		background-image: unset !important;
	}
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
	background-color: transparent;
}
.no-scrollbar::-webkit-scrollbar-track {
	background-color: transparent;
}
.no-scrollbar::-webkit-scrollbar-thumb {
	background-color: transparent;
}
.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

@layer components {
	.maia-insurance-product-toggle .react-toggle-track {
		@apply border-primaryMain bg-primary50 h-24 w-40 border;
	}

	.maia-insurance-product-toggle.maia-insurance-product-toggle-onOff .react-toggle-track {
		@apply border-grayscale100 bg-grayscale100;
	}

	.maia-insurance-product-toggle:hover:not(.react-toggle--disabled, .react-toggle--checked) .react-toggle-track {
		@apply bg-primary100;
	}

	.maia-insurance-product-toggle.maia-insurance-product-toggle-onOff:hover:not(
			.react-toggle--disabled,
			.react-toggle--checked
		)
		.react-toggle-track {
		@apply bg-grayscale200 border-grayscale200;
	}

	.maia-insurance-product-toggle:active:not(.react-toggle--disabled, .react-toggle--checked) .react-toggle-track {
		@apply bg-primary200;
	}

	.maia-insurance-product-toggle.maia-insurance-product-toggle-onOff:active:not(
			.react-toggle--disabled,
			.react-toggle--checked
		)
		.react-toggle-track {
		@apply bg-grayscale300 border-grayscale300 text-grayscale400;
	}

	.maia-insurance-product-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
		@apply bg-primary600;
	}

	.maia-insurance-product-toggle.maia-insurance-product-toggle-onOff.react-toggle--checked:hover:not(
			.react-toggle--disabled
		)
		.react-toggle-track {
		@apply bg-success300 border-success300 text-success300;
	}

	.maia-insurance-product-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
		@apply shadow-none;
	}

	.maia-insurance-product-toggle .react-toggle-thumb {
		@apply bg-primaryMain left-4 top-4 h-16 w-16 border-none;
	}

	.maia-insurance-product-toggle.maia-insurance-product-toggle-onOff .react-toggle-thumb {
		@apply bg-grayscale0 text-grayscale300;
	}

	.maia-insurance-product-toggle.maia-insurance-product-toggle-onOff .react-toggle-track-x {
		@apply text-grayscale300 left-[7px] z-10;
	}

	.maia-insurance-product-toggle.maia-insurance-product-toggle-onOff .react-toggle-track-check {
		@apply text-success200 left-[23px] z-10;
	}

	.maia-insurance-product-toggle.react-toggle--focus .react-toggle-thumb {
		@apply shadow-none;
	}

	.maia-insurance-product-toggle.react-toggle--checked .react-toggle-track {
		@apply bg-primaryMain;
	}

	.maia-insurance-product-toggle.maia-insurance-product-toggle-onOff.react-toggle--checked .react-toggle-track {
		@apply bg-success200 border-success200;
	}

	.maia-insurance-product-toggle.react-toggle--checked .react-toggle-thumb {
		@apply bg-grayscale0 left-20 top-4 border-none;
	}
}

.typography-display-1 {
	@apply text-display-1-mobile md:text-display-1-desktop leading-display-1-mobile md:leading-display-1-desktop;
}

.typography-display-2 {
	@apply text-display-2-mobile md:text-display-2-desktop leading-display-2-mobile md:leading-display-2-desktop;
}

.typography-display-3 {
	@apply text-display-3-mobile md:text-display-3-desktop leading-display-3-mobile md:leading-display-3-desktop;
}

.typography-display-4 {
	@apply text-display-4-mobile md:text-display-4-desktop leading-display-4-mobile md:leading-display-4-desktop;
}

.typography-display-5 {
	@apply text-display-5-mobile md:text-display-5-desktop leading-display-5-mobile md:leading-display-5-desktop;
}

.typography-display-6 {
	@apply text-display-6-mobile md:text-display-6-desktop leading-display-6-mobile md:leading-display-6-desktop;
}

.typography-body-l {
	@apply text-body-l-mobile md:text-body-l-desktop leading-body-l-mobile md:leading-body-l-desktop;
}

.typography-body-m {
	@apply text-body-m-mobile md:text-body-m-desktop leading-body-m-mobile md:leading-body-m-desktop;
}

.typography-body-s {
	@apply text-body-s-mobile md:text-body-s-desktop leading-body-s-mobile md:leading-body-s-desktop;
}

.typography-body-xs {
	@apply text-body-xs-mobile md:text-body-xs-desktop leading-body-xs-mobile md:leading-body-xs-desktop;
}

.typography-body-xxs {
	@apply text-body-xxs-mobile md:text-body-xxs-desktop leading-body-xxs-mobile md:leading-body-xxs-desktop;
}

/* Global modal Transition styling*/
.ReactModal__Overlay {
	@apply bg-transparent backdrop-blur transition-all duration-200 ease-in-out;
}

.ReactModal__Overlay--after-open {
	@apply bg-overlayColor backdrop-blur;
}

.ReactModal__Overlay--before-close {
	@apply bg-overlayColor backdrop-blur-0 transition-all duration-200 ease-in-out;
}

.ReactModal__Overlay > div:first-child {
	@apply opacity-100 transition-all duration-300 ease-out max-md:translate-y-full md:opacity-0;
}

.ReactModal__Overlay--after-open > div:first-child {
	@apply opacity-100 max-md:translate-y-0;
}

.ReactModal__Overlay--before-close > div:first-child {
	@apply opacity-100 transition-all duration-300 ease-out max-md:translate-y-full md:opacity-0;
}

/* Global tooltip styling*/
.__react_component_tooltip {
	background: var(--colors-secondaryMain) !important;
	border-radius: var(--borderRadius-6) !important;
	padding: 16px !important;
}

.__react_component_tooltip:before {
	background: var(--colors-secondaryMain) !important;
}

.__react_component_tooltip:after {
	background: var(--colors-secondaryMain) !important;
}

.__react_component_tooltip.show {
	opacity: 1 !important;
}

.react-select__input {
	box-shadow: none !important;
	outline-offset: 0px !important;
}

.hyphens-none {
	hyphens: none;
}

.hyphens-manual {
	hyphens: manual;
}

.hyphens-auto {
	hyphens: auto;
}

/* Hide browser controls on number inputs */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

input[type='number'] {
	-moz-appearance: textfield !important;
}

.zigzag {
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 24px;
		background: conic-gradient(from 135deg at top, transparent, transparent 1deg 89deg, #fff 90deg) 50%/24px 100%;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
	}
}

.react-datepicker {
	font-family: var(--font-lexend);
	@apply border-grayscale100 shadow-1 rounded-6 border p-24;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker .react-datepicker__triangle {
	display: none;
}

/* Datepicker */
.react-datepicker .react-datepicker__header {
	@apply bg-grayscale0 space-y-24 border-0 p-0;
}

.react-datepicker .react-datepicker__month-container {
	@apply space-y-8;
}

.react-datepicker .react-datepicker__day-names {
	@apply text-body-s-desktop flex justify-around space-x-12 border-0 font-light;
}

.react-datepicker .react-datepicker__day-name {
	@apply text-grayscale200 m-0 capitalize;
}

.react-datepicker .react-datepicker__month {
	@apply m-0 space-y-4;
}

.react-datepicker .react-datepicker__week {
	@apply flex items-center justify-center space-x-12;
}

.react-datepicker .react-datepicker__day {
	@apply text-grayscale600 bg-grayscale0 text-body-s-desktop rounded-6 m-0 flex h-32 w-32 items-center justify-center border border-transparent font-light;
}

.react-datepicker .react-datepicker__day--disabled {
	@apply text-grayscale200 cursor-not-allowed;
}

.react-datepicker .react-datepicker__day:hover {
	@apply bg-primary100 text-primaryMain font-medium;
}

.react-datepicker .react-datepicker__day--outside-month {
	@apply text-grayscale200 font-light;
}

.react-datepicker .react-datepicker__day--today {
	@apply border-primary100;
}

.react-datepicker .react-datepicker__day--selected {
	@apply bg-primaryMain text-grayscale0 font-medium;
}

/* Odometer */
.odometer-inside .odometer-digit {
	padding-right: 1px;
}

.break-words-force {
	word-break: break-word;
}
